import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

const initialState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const selectIsLoading = (state: RootState) => state.loading.isLoading;

export const { setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
