import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthState } from '../models/authModel';
import { RootState } from '../store';

const initialState: AuthState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AuthState['user']>) {
      state.user = action.payload;
    },
    clearAuth: () => initialState,
  },
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectIsAuthenticated = (state: RootState) => !!state.auth.user;
export const selectUserId = (state: RootState) => state.auth.user?._id;
export const selectStoreId = (state: RootState) => state.auth.user?.storeId;

export const { setUser, clearAuth } = authSlice.actions;

export default authSlice.reducer;
