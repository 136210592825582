import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DashboardPage from './components/pages/DashboardPage';
import SignInPage from './components/pages/SignInPage/SignInPage';
import StorePage from './components/pages/StorePage';
import UserPage from './components/pages/UserPage';

import ProtectedRoute from './components/organisms/ProtectedRoute';
import PublicOnlyRoute from './components/organisms/PublicOnlyRoute';

const Router: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/dashboard' replace />} />
      <Route element={<PublicOnlyRoute />}>
        <Route path='/signin' element={<SignInPage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path='/dashboard' element={<DashboardPage />} />
        <Route path='/store/*' element={<StorePage />} />
        <Route path='/user' element={<UserPage />} />
      </Route>
      <Route path='*' element={<Navigate to='/dashboard' replace />} />
    </Routes>
  );
};

export default Router;
