import { Button, FormControl, FormHelperText, FormLabel, Input, Sheet, Typography } from '@mui/joy';
import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorObjectToString } from 'src/utils/common';

import useAuth from 'src/redux/hooks/useAuth';
import useLoading from 'src/redux/hooks/useLoading';
import useModal from 'src/redux/hooks/useModal';

import { useTitle } from 'src/hooks/useTitle';

import classes from './SignInPage.module.scss';

interface SignInFormInputs {
  email: string;
  password: string;
}

const SignInPage: FC = () => {
  useTitle('SignIn');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormInputs>();
  const { handleSignIn } = useAuth();
  const { handleSetLoading } = useLoading();
  const { openModal } = useModal();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const onSubmit = useCallback(
    async (data: SignInFormInputs) => {
      handleSetLoading(true);
      try {
        await handleSignIn(data.email, data.password);
        navigate(from, { replace: true });
      } catch (error) {
        console.error(error);
        const errorMessage = errorObjectToString(error);
        openModal('danger', '로그인 실패', errorMessage);
      }
      handleSetLoading(false);
    },
    [from, handleSetLoading, handleSignIn, navigate, openModal]
  );

  return (
    <main>
      <Sheet className={classes.signInSheet} sx={{ boxShadow: 'md' }} variant='outlined'>
        <div>
          <Typography level='h4' component='h1'>
            <b>굿엉클스 모바일 관리자</b>
          </Typography>
          <Typography level='body-sm'>Sign in to continue.</Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <FormControl error={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              // html input attribute
              type='email'
              placeholder='nickname@email.com'
              {...register('email', {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/, // 이메일 정규식
                  message: 'Invalid email address', // 오류 메시지
                },
              })}
            />
            <FormHelperText>{errors.email ? errors.email.message : ''}</FormHelperText>
          </FormControl>
          <FormControl error={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              // html input attribute
              type='password'
              placeholder='password'
              {...register('password', {
                required: true,
                minLength: {
                  value: 6, // 최소 6자리
                  message: 'Password must have at least 6 characters',
                },
              })}
            />
            <FormHelperText>{errors.password ? errors.password.message : ''}</FormHelperText>
          </FormControl>

          <Button sx={{ mt: 1 }} type='submit'>
            Log in
          </Button>
        </form>
      </Sheet>
    </main>
  );
};

export default SignInPage;
