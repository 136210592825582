import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import loading from './loading';
import modal from './modal';
import stores from './stores';

const rootReducer = combineReducers({
  auth,
  modal,
  stores,
  loading,
});

export default rootReducer;
