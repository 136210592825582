import ReportIcon from '@mui/icons-material/Report';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Typography } from '@mui/joy';
import { FC } from 'react';

import useModal from 'src/redux/hooks/useModal';
import { selectModal } from 'src/redux/slices/modal';
import { useAppSelector } from 'src/redux/store';

const AlertModal: FC = () => {
  const { open, title, message, color } = useAppSelector(selectModal);
  const { closeModal } = useModal();
  return (
    <Modal open={open} onClose={closeModal}>
      <ModalDialog variant='outlined' role='alertdialog' color={color}>
        {title && (
          <>
            <DialogTitle color={color}>
              {color === 'danger' && <ReportIcon />}
              <Typography color={color}>{title}</Typography>
            </DialogTitle>
            <Divider />
          </>
        )}
        {message && (
          <DialogContent>
            <Typography color={color}>{message}</Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant='plain' color='neutral' onClick={closeModal}>
            닫기
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default AlertModal;
