import { FC, PropsWithChildren } from 'react';

import classes from './AppBar.module.scss';

const AppBar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <header className={classes.appBar}>
        {children}
        <h3>{document.title}</h3>
      </header>
      {/* <div className={classes.appBarBuffer} /> */}
    </>
  );
};

export default AppBar;
