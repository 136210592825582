export const logArt = `개발자 여러분, 오늘도 고생이 많으십니다.
🥙🥘🍚🍖🥩🍕🍣🍝🥟🍜🍔🍙🍲
`;
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID === 'null' ? undefined : process.env.REACT_APP_MEASUREMENT_ID,
};

export const isProduction = process.env.REACT_APP_PROJECT_ID === 'gooduncles-10f09';

export const LOCAL_STORAGE_KEY = {
  sessionId: 'sessionId',
};
