import '@fontsource/inter';
import { CssBaseline } from '@mui/joy';

/**
 * 아래는 joy-ui의 스타일이 깨지지 않도록 해주는 코드로 스타일 리셋을 해준다.
 * {@link https://mui.com/joy-ui/react-css-baseline CssBaseline}
 */
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { BrowserRouter } from 'react-router-dom';

import useAuthentication from './hooks/useAuthentication';

import AlertModal from './components/molecules/AlertModal/AlertModal';
import LoadingModal from './components/molecules/LoadingModal/LoadingModal';

import './App.css';
import './variables.css';

import Router from './Router';

const theme = extendTheme({
  components: {
    JoyDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-backdrop': {
            backdropFilter: 'none',
          },
        },
      },
    },
    JoyModal: {
      styleOverrides: {
        root: {
          '& .MuiModal-backdrop': {
            backdropFilter: 'none',
          },
        },
      },
    },
  },
});

function App() {
  // 로그인 관련 로직 처리
  useAuthentication();
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <LoadingModal />
      <AlertModal />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </CssVarsProvider>
  );
}

export default App;
