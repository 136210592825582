import { UserDoc } from '@gooduncles/gu-app-schema';
import { FirebaseManager } from 'src/utils/firebaseManager';

const fb = FirebaseManager.getInstance();

const userPath = 'user';

export const observeUserDoc = (uid: string) => fb.observeDoc<UserDoc>(`${userPath}/${uid}`);

export const getUserDoc = async (uid: string) => fb.getDoc<UserDoc>(`${userPath}/${uid}`);

export const updateUser = (uid: string, data: Partial<UserDoc>) => fb.updateDoc(userPath, uid, data);
