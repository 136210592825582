import { ComponentType, FC } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * 쿼리 파라미터를 컴포넌트의 props로 전달해주는 HOC입니다.
 */
const withSearchParams = <P extends object>(WarppedComponent: ComponentType<P>) => {
  const ComponentWithSearchParams: FC<P> = (props) => {
    const [searchParams] = useSearchParams();
    const params: { [key: string]: string } = {};

    // 모든 쿼리 파라미터를 객체에 담습니다.
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return <WarppedComponent {...(props as P)} {...params} />;
  };
  return ComponentWithSearchParams;
};

export default withSearchParams;
