import { Cancel, CheckCircle, Edit } from '@mui/icons-material';
import { Button, FormLabel, IconButton, Input, Stack, Typography } from '@mui/joy';
import { FC, useCallback, useState } from 'react';
import { updateDoc } from 'src/services/commonService';
import { errorObjectToString } from 'src/utils/common';

import useModal from 'src/redux/hooks/useModal';

interface EditableFieldProps {
  label: string;
  field: string;
  value: string;
  collectionPath: string;
  docId: string;
  nullable?: boolean;
  validate?: (value: string) => Promise<void>;
}

const EditableTextField: FC<EditableFieldProps> = ({ label, field, value, collectionPath, docId, nullable, validate }) => {
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(value ?? '');
  const { openModal } = useModal();

  const handleOnEditable = () => {
    setEditable(true);
  };

  const handleCancelEdit = () => {
    setEditable(false);
  };

  const handleOnEdit = useCallback(async () => {
    if (inputValue === value) {
      setEditable(false);
      return;
    }
    setLoading(true);
    try {
      if (!nullable && inputValue.length === 0) {
        throw new Error('값을 입력해주세요.');
      }
      if (validate) {
        await validate(inputValue);
      }
      await updateDoc(collectionPath, docId, { [field]: inputValue });
    } catch (error) {
      console.error(error);
      const errorMsg = errorObjectToString(error);
      setInputValue(value);
      openModal('danger', '수정 실패', errorMsg);
    }
    setEditable(false);
    setLoading(false);
  }, [collectionPath, docId, field, inputValue, nullable, openModal, validate, value]);

  return (
    <Stack>
      {label && <FormLabel>{label}</FormLabel>}
      {editable ? (
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          endDecorator={
            <Stack flexDirection='row' alignItems='center' gap={1}>
              <Button size='sm' variant='plain' color='danger' onClick={handleCancelEdit} loading={loading}>
                <Cancel />
              </Button>
              <Button size='sm' variant='plain' color='success' onClick={handleOnEdit} loading={loading}>
                <CheckCircle />
              </Button>
            </Stack>
          }
        />
      ) : (
        <Stack flexDirection='row' alignItems='center' minHeight={36}>
          <Typography sx={{ flex: 1 }}>{!value || value.length === 0 ? '-' : value}</Typography>
          <IconButton size='sm' onClick={handleOnEditable}>
            <Edit />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default EditableTextField;
