import { StoreDoc, StoreState } from '@gooduncles/gu-app-schema';
import { storeStateList } from '@gooduncles/gu-app-schema/constants';
import { CheckCircle, FilterAltOutlined } from '@mui/icons-material';
import {
  Badge,
  Divider,
  Dropdown,
  IconButton,
  Input,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
} from '@mui/joy';
import { debounce } from 'lodash-es';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observeStores } from 'src/services/storeService';

import withSearchParams from 'src/components/hocs/withSearchParams';
import StoreItem from 'src/components/molecules/StoreItem/StoreItem';
import StoreModal from 'src/components/molecules/StoreModal/StoreModal';

import classes from './StoreList.module.scss';

const regexForStoreCode = /R(\d{5})/;

interface StoreListProps {
  state?: StoreState;
}

const StoreList: FC<StoreListProps> = ({ state = 'all' }) => {
  const navigate = useNavigate();
  const [stores, setStores] = useState<StoreDoc[] | null>(null);
  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null);
  const [keyword, setKeyword] = useState('');
  const isFilterSet = state !== 'all';
  const newStoreCode = useMemo(() => {
    if (!stores) return null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const codes = stores?.filter(s => regexForStoreCode.test(s.storeCode ?? '')).map(s => Number(s.storeCode!.replace('R', ''))).filter(v => !isNaN(v)).sort();
    return codes[codes.length - 1] + 1;
  }, [stores]);
  const storesForState = useMemo(() => stores?.filter((store) => state !== 'all' ? store.state === state : true) ?? [], [stores, state]);
  const filteredStores =
    keyword.length > 0 ? storesForState?.filter((store) => {
      return store.storeNickname.includes(keyword) || store.storeEmail?.includes(keyword)
    }) ?? [] : storesForState ?? [];

  const handleStoreClick = (id: string) => {
    setSelectedStoreId(id);
  };

  const clearSelectedStore = () => {
    setSelectedStoreId(null);
  };

  const handleFilterChange = (state: string) => {
    navigate(state === 'all' ? '/store/list' : `/store/list?state=${state}`, { replace: true });
  };

  useEffect(() => {
    const subscription = observeStores([], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
    }).subscribe((stores) => {
      setStores(stores);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [state]);

  return (
    <>
      <Stack>
        <div className={classes.filterBox}>
          <Input size='sm' sx={{ flex: 1 }} placeholder='가게명 또는 이메일 검색' onChange={debounce((e) => setKeyword(e.target.value), 300)} />
          <Dropdown>
            <MenuButton
              variant='plain'
              slots={{ root: IconButton }}
              slotProps={{
                root: {
                  variant: 'plain',
                  size: 'sm',
                },
              }}
            >
              {isFilterSet ? (
                <Badge size='sm'>
                  <FilterAltOutlined />
                </Badge>
              ) : (
                <FilterAltOutlined />
              )}
            </MenuButton>
            <Menu>
              <MenuItem aria-checked={'all' === state} onClick={() => handleFilterChange('all')}>
                <ListItemDecorator>{'all' === state && <CheckCircle />}</ListItemDecorator>모두보기
              </MenuItem>
              {Object.entries(storeStateList).map(([storeState, label]) => (
                <MenuItem
                  key={storeState}
                  aria-checked={storeState === state}
                  sx={{ fontWeight: storeState === state ? 600 : 400 }}
                  onClick={() => handleFilterChange(storeState)}
                >
                  <ListItemDecorator>{storeState === state && <CheckCircle />}</ListItemDecorator>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        </div>
        <Stack sx={{ px: 2 }}>
          {filteredStores?.map((store) => (
            <Fragment key={'frag' + store._id}>
              <StoreItem store={store} onClick={() => handleStoreClick(store._id)} />
              <Divider sx={{ my: 1 }} />
            </Fragment>
          ))}
        </Stack>
      </Stack>
      <StoreModal
        store={selectedStoreId !== null ? stores?.find((s) => s._id === selectedStoreId) ?? null : null}
        newStoreCode={newStoreCode}
        handleCloseModal={clearSelectedStore}
      />
    </>
  );
};

export default withSearchParams(StoreList);
