import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { selectIsAuthenticated } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import AppBar from '../molecules/AppBar/AppBar';
import SideNavigation from '../molecules/SideNavigation/SideNavigation';

const ProtectedRoute: FC = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to='/signin' replace />;
  }

  return (
    <>
      <AppBar>
        <SideNavigation />
      </AppBar>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
