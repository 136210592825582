import { ListItem, ListItemButton } from '@mui/joy';
import { FC } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

interface NavigationItemProps {
  title: string;
  path: string;
}

const NavigationItem: FC<NavigationItemProps> = ({ title, path }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isActive = (path: string) => {
    return path.includes(currentPath);
  };

  return (
    <ListItem component={ReactRouterLink} to={path} sx={{ textDecoration: 'unset' }}>
      <ListItemButton selected={isActive(path)} color={isActive(path) ? 'primary' : undefined}>
        {title}
      </ListItemButton>
    </ListItem>
  );
};

export default NavigationItem;
