import { StoreState } from '@gooduncles/gu-app-schema';
import { getCollectionCount } from 'src/services/commonService';
import useSWR from 'swr';

const fetcher = (path: string) => {
  const [collection, state = undefined] = path.split('/');
  return getCollectionCount(collection, state ? [['state', '==', state]] : []);
};

const useStoresCount = (state?: StoreState) => {
  const { data, error, isLoading } = useSWR(state ? `store/${state}` : 'store', fetcher);

  return {
    count: data,
    error,
    isLoading,
  };
};

export default useStoresCount;
