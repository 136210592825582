import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Divider, Drawer, IconButton } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useAuth from 'src/redux/hooks/useAuth';
import { selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import classes from './SideNavigation.module.scss';

import NaviList from '../NaviList/NaviList';

const SideNavigation = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const user = useAppSelector(selectUser);
  const { handleSignOut } = useAuth();

  const openSideNavigation = () => setOpen(true);
  const closeSideNavigation = () => setOpen(false);

  useEffect(() => {
    closeSideNavigation();
  }, [location]);

  if (!user) return null;

  return (
    <>
      <IconButton variant='outlined' size='sm' onClick={openSideNavigation}>
        <MenuIcon />
      </IconButton>
      <Drawer open={open} size='sm' onClose={closeSideNavigation}>
        <div className={classes.sizeNaviContainer}>
          <div className={classes.sideNaviHeader}>
            <ModalClose id='close-icon' size='md' sx={{ position: 'initial' }} />
          </div>

          <div className={classes.sideNaviBody}>
            <NaviList />
          </div>

          <Divider />
          <div className={classes.sideNaviFooter}>
            <Avatar src='/static/images/avatar/1.jpg' />
            <div>
              <Typography level='title-sm'>{user.role}</Typography>
              <Typography level='body-xs' noWrap>
                {user.email}
              </Typography>
            </div>
            <IconButton size='sm' onClick={handleSignOut} sx={{ marginLeft: 'auto' }}>
              <LogoutIcon />
            </IconButton>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SideNavigation;
