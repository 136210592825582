import { setLoading } from '../slices/loading';
import { useAppDispatch } from '../store';

const useLoading = () => {
  const dispatch = useAppDispatch();

  const handleSetLoading = (loading: boolean) => {
    dispatch(setLoading(loading));
  };

  return { handleSetLoading };
};

export default useLoading;
