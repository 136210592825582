import { StoreDoc } from '@gooduncles/gu-app-schema';
import { FirebaseManager, QueryOption, WHERE } from 'src/utils/firebaseManager';

const fb = FirebaseManager.getInstance();

const storePath = 'store';

export const getStoresWithQuery = (wheres: WHERE[], queries: QueryOption[], itemsPerQuery?: number) =>
  fb.getDocsArrayWithQuery<StoreDoc>(storePath, wheres, queries, itemsPerQuery);

export const observeStores = (
  wheres: WHERE[],
  options?: {
    sortKey: string;
    orderBy: 'asc' | 'desc';
    startAfter?: any;
    endBefore?: any;
    limit?: number;
  }
) => fb.observeCollection<StoreDoc>(storePath, wheres, options);
