import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { selectIsAuthenticated } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

interface PublicOnlyRouteProps {
  redirectTo?: string;
}

const PublicOnlyRoute: FC<PublicOnlyRouteProps> = ({ redirectTo = '/' }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
};

export default PublicOnlyRoute;
