import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ModalState } from '../models/modalModel';
import { RootState } from '../store';

const initialState: ModalState = {
  color: 'danger',
  open: false,
  title: null,
  message: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal(state, action: PayloadAction<ModalState>) {
      const { color, open, title, message } = action.payload;
      state.color = color;
      state.open = open;
      state.title = title;
      state.message = message;
    },
    clearModal: () => initialState,
  },
});

export const selectModal = (state: RootState) => state.modal;

export const { setModal, clearModal } = modalSlice.actions;

export default modalSlice.reducer;
