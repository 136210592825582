import { ColorPaletteProp } from '@mui/joy';

import { clearModal, setModal } from '../slices/modal';
import { useAppDispatch } from '../store';

const useModal = () => {
  const dispatch = useAppDispatch();

  const openModal = (color: ColorPaletteProp, title: string | null, message: string | null) => {
    dispatch(setModal({ color, open: true, title, message }));
  };

  const closeModal = () => {
    dispatch(clearModal());
  };

  return { openModal, closeModal };
};

export default useModal;
