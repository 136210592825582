import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { StoresState } from '../models/storesModel';
import { RootState } from '../store';

const initialState: StoresState = {
  list: [],
};

const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setStoreList(state, action: PayloadAction<StoresState['list']>) {
      state.list = action.payload;
    },
    clearStoreList: () => initialState,
  },
});

export const selectStoreList = (state: RootState) => state.stores.list;

export const { setStoreList, clearStoreList } = storesSlice.actions;

export default storesSlice.reducer;
