import { fromEvent, map } from 'rxjs';
import { jsonParseWithCatch } from './common';

export function setLocalStorageValue<T>(key: string, newValue: T) {
  localStorage.setItem(key, JSON.stringify(newValue));
  window.dispatchEvent(new Event('storage'));
}

export function removeLocalStorageItem(key: string) {
  localStorage.removeItem(key);
  window.dispatchEvent(new Event('storage'));
}

export function getLocalStorageValue<T>(key: string) {
  const storedLocalValue = jsonParseWithCatch<T>(localStorage.getItem(key));
  return storedLocalValue;
}

export function observeLocalStorageValue<T>(key: string) {
  return fromEvent<StorageEvent>(window, 'storage').pipe(map(() => getLocalStorageValue<T>(key)));
}
