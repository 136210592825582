import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useTitle } from 'src/hooks/useTitle';

import StoreList from '../organisms/StoreList/StoreList';

const StorePage: FC = () => {
  useTitle('Store');
  return (
    <div>
      <Routes>
        <Route path='list' element={<StoreList />} />
      </Routes>
    </div>
  );
};

export default StorePage;
