import { LOCAL_STORAGE_KEY } from 'src/constants';
import { getLocalStorageValue, setLocalStorageValue } from './localStorage';
import { v4 } from 'uuid';

/**
 * 값이 undefined인 경우에 대한 처리를 추가한 JSON.parse
 */
export function jsonParseWithCatch<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? 'null');
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export const isLocal = ['127.0.0.1', 'localhost'].includes(window.location.hostname);

export const sessionId = () => {
  const id = getLocalStorageValue<string>(LOCAL_STORAGE_KEY.sessionId);
  if (id) {
    return id;
  }
  const newId = !isLocal ? v4() : 'hubert';
  setLocalStorageValue(LOCAL_STORAGE_KEY.sessionId, newId);
  return newId;
};

export const sleep = async (ms?: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * redux & ga에서 사용불가한
 * Timestamp를 제거한다.
 */
export const deleteTimestamp = (data: any) => {
  delete data._timeUpdate;
  delete data._timeCreate;
  delete data._timeDelete;
  return data;
};

export async function getPublicIp() {
  try {
    const response = await fetch('https://api64.ipify.org?format=json', {
      cache: 'no-cache',
    });
    if (response.ok) {
      const body = await response.json();
      return body.ip as string;
    } else {
      const response = await fetch('https://us-central1-gooduncles-10f09.cloudfunctions.net/myip', {
        cache: 'no-cache',
      });
      if (response.ok) {
        const body = await response.json();
        return body.ip as string;
      }
      return 'unknown';
    }
  } catch (error) {
    console.error('[getPublicIp]', error);
    return 'error';
  }
}

export const parseIfObject = (value: any) => {
  if (typeof value === 'object') {
    return value instanceof Error ? JSON.stringify(value, Object.getOwnPropertyNames(value)) : JSON.stringify(value);
  }
  return value;
};

export const errorObjectToString = (error: any) => (error instanceof Error ? error.message : parseIfObject(error));
