import { User } from '@gooduncles/gu-app-schema';

export const validateUser = (user: User) => {
  if (!user) {
    throw new Error('사용자 정보를 찾을 수 없습니다.');
  }
  if (user.status !== 'active') {
    throw new Error('사용중지된 계정입니다.');
  }
  if (!user.storeId) {
    throw new Error('등록된 매장이 없는 계정입니다.');
  }
};
