import { useCallback } from 'react';
import { deleteTimestamp } from 'src/utils/common';

import { signIn, signOut } from '../../services/authService';
import { UserExceptFavoriteProducts } from '../models/authModel';
import { clearAuth, setUser } from '../slices/auth';
import { setLoading } from '../slices/loading';
import { useAppDispatch } from '../store';

const useAuth = () => {
  const dispatch = useAppDispatch();

  const handleSignIn = useCallback(
    async (email: string, password: string) => {
      await signIn(email, password);
    },
    []
  );

  const handleSignOut = useCallback(async () => {
    await signOut();
    dispatch(clearAuth());
    dispatch(setLoading(false));
  }, [dispatch]);

  const handleSetUser = useCallback(
    (user: UserExceptFavoriteProducts) => {
      deleteTimestamp(user);
      dispatch(setUser(user));
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  return {
    handleSignIn,
    handleSignOut,
    handleSetUser,
  };
};

export default useAuth;
