import {
  Analytics,
  AnalyticsCallOptions,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import * as firebase from 'firebase/app';
import {
  Auth,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  DocumentData,
  Firestore,
  Query,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  UpdateData,
  WhereFilterOp,
  WriteBatch,
  collection,
  deleteDoc,
  doc,
  endBefore,
  getDoc as firebaseGetDoc,
  setDoc as firebaseSetDoc,
  updateDoc as firebaseUpdateDoc,
  getCountFromServer,
  getDocFromServer,
  getDocs,
  getFirestore,
  initializeFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
  writeBatch,
} from 'firebase/firestore';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import {
  FirebaseStorage,
  StorageReference,
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { Observable } from 'rxjs';

import { sleep } from './common';
import { FIREBASE_CONFIG } from 'src/constants';

export type CallableName = 'callCreateUser';

export type WHERE = [string, WhereFilterOp, any];
export interface QueryOption {
  sort: 'asc' | 'desc';
  orderBy: string;
  startAfter?: any;
  endBefore?: any;
}

const logger = console;
/**
 * timestamp 필드로 되어 있는 값은 받으면 seconds와 nanoseconds 필드로 변환된다.
 * 이 값을 이용해서 다시 firestore.Timestamp()로 복구한다.
 * 조사 필드는 _time으로 시작하는 4개다.
 */
const recoverTimestamp = (doc: UpdateData<any>) => {
  ['Create', 'Update', 'Set', 'Merge']
    .map((command) => `_time${command}`)
    .forEach((key) => {
      const value = doc[key];
      if (value) {
        if (value instanceof Object && value.seconds !== undefined && value.nanoseconds !== undefined) {
          doc[key] = new Timestamp(value.seconds, value.nanoseconds).toDate();
        }
      }
    });

  return doc;
};

export class FirebaseManager {
  // 지금은 단일 firebase project만 사용중이다.
  // 추후 복수의 사용이 필요할 때 객체로 전환하자.
  private static instance: FirebaseManager;
  private auth: Auth;
  private firestore: Firestore;
  private storage: FirebaseStorage;
  private storageRef: StorageReference;
  private functions: Functions;

  // 최대 500개로 되어 있지만
  // serverTimestamp() 는 1이 더 추가된다고 한다.
  // 그래서 안전하게 250으로 한다.
  // refer: https://github.com/firebase/firebase-admin-node/issues/456
  private readonly MaxBatchNum = 150;
  private batch?: WriteBatch;
  private batchCount = 0;
  private analytics: Analytics;

  public static getInstance() {
    if (FirebaseManager.instance === undefined) {
      FirebaseManager.instance = new FirebaseManager();
    }

    return FirebaseManager.instance;
  }

  constructor() {
    const app = firebase.initializeApp(FIREBASE_CONFIG);
    initializeFirestore(app, {
      ignoreUndefinedProperties: true,
    });
    this.analytics = getAnalytics(app);
    this.auth = getAuth();
    this.firestore = getFirestore();
    this.storage = getStorage();
    this.storageRef = ref(this.storage, 'onlineClassFiles');
    this.functions = getFunctions(app);
  }

  /**
   *
   * @param eventName
   * @param eventParams
   * @param options AnalyticsCallOptions의 global 옵션은 이벤트를 전역적으로 보고할지 여부를 결정합니다.
   *
   * global: true: 이 옵션을 사용하여 이벤트를 전역적으로 보고하면
   * 해당 이벤트는 현재 Firebase 프로젝트뿐만 아니라 모든 Firebase 프로젝트에 보고됩니다.
   * 따라서, 이벤트가 여러 Firebase 프로젝트에서 공유되어 분석되고 사용자 행동을 추적하는 데 사용될 수 있습니다.
   *
   * global: false (기본값): 이 옵션을 사용하지 않거나 global: false로 설정하면 이벤트는 현재 Firebase 프로젝트에만 보고됩니다.
   * 이벤트는 해당 Firebase 프로젝트 내에서 분석되고 사용자 행동을 추적하는 데 사용됩니다.
   * 다른 Firebase 프로젝트에는 해당 이벤트가 전달되지 않습니다.
   */
  public gaLogEvent(eventName: string, eventParams: any, options?: AnalyticsCallOptions) {
    return logEvent(this.analytics, eventName, eventParams, options);
  }

  /**
   * firebase authentication uid를 기록한다.
   */
  public setGaUserId(uid: string) {
    return setUserId(this.analytics, uid);
  }

  /**
   * 실제 서비스 user 정보를 기록한다.
   */
  public setGaUserProperties(userProperties: { [key: string]: string }) {
    return setUserProperties(this.analytics, userProperties);
  }

  /**
   * @param path #, [, ], * 또는 ?는 사용하면 안된다.
   */
  private getStorageRef(path: string) {
    return ref(this.storageRef, path);
  }

  public getCallable<REQUEST, RESPONSE>(callableName: CallableName) {
    return httpsCallable<REQUEST, RESPONSE>(this.functions, callableName);
  }

  public uploadTask(path: string, file: File) {
    if (!path || !file) {
      throw new TypeError('path나 file이 없는 것 같아요');
    }

    const fileRef = this.getStorageRef(path);
    return uploadBytesResumable(fileRef, file);
  }

  public deleteFile(path: string) {
    if (!path) {
      throw new TypeError('path가 없는 것 같아요');
    }

    const fileRef = this.getStorageRef(path);
    return deleteObject(fileRef);
  }

  public setDownloadURL(ref: StorageReference) {
    return getDownloadURL(ref);
  }

  public batchStart() {
    this.batch = writeBatch(this.firestore);
    this.batchCount = 0;
  }

  public async batchEnd() {
    const fnName = 'batchEnd';

    if (this.batch === undefined) {
      logger.error(`[${fnName}] No this.batch. Run batchStart() first.`);
      return false;
    }

    if (this.batchCount > 0) {
      logger.info(`[${fnName}] batchCount == ${this.batchCount} => Run batch.commit()`);
      this.batchCount = 0;
      await this.batch.commit();
    } else {
      logger.info(`[${fnName}] batchCount == ${this.batchCount} => NOOP`);
    }

    return undefined;
  }

  public createDocId(collectionPath: string) {
    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);
    const docRef = doc(collectionRef);
    return docRef.id;
  }

  public async isDocExist(path: string) {
    const firestore = this.firestore;
    const docRef = doc(firestore, path);

    const getDocData = await firebaseGetDoc(docRef);
    return getDocData.exists();
  }

  /**
   * 지정한 collection에 doc을 추가한다
   * documennt Id는 자동생한다.
   *
   * bMerge의 경우에 doc이 다음과 같다면
   * ```
   * k1: {
   *  k2: v1
   * }
   * ```
   * k1을 전체 업데이트 하는 것이 아니라 k1.k2만을 업데이트 하거나 추가한다는 사실을 명심해야 한다.
   * k1.k3와 같은 키가 있다면 유지되는 것이다. path의 개념으로 이해해야 한다.
   *
   * 특정 필드를 삭제하려면 다음과 같은 특별한 값을 지정해야 한다.
   * `deletingKey: admin.firestore.FieldValue.delete()`
   *
   * refer : https://cloud.google.com/nodejs/docs/reference/firestore/0.20.x/DocumentReference
   *
   * - options.idAsField = false: false => id doc의 id로 사용, true => id는 doc의 필드를 가리킨다.
   * - options.bMerge = true: true이면 지정한 필드만 업데이트한다.
   * - options.addMetadata = true: _id, _timeUpdate 필드를 자동으로 생성한다.
   * - options.bBatch = false: batch에 추가한다. batchStart(), batchEnd()와 함께 사용한다.
   */
  public async setDoc(
    collectionPath: string,
    id: string | undefined,
    docData: DocumentData,
    options?: {
      idAsField?: boolean;
      bMerge?: boolean;
      addMetadata?: boolean;
      bBatch?: boolean;
    }
  ) {
    const fnName = 'setDoc';

    const { idAsField = false, bMerge = true, addMetadata = true, bBatch = false } = options ?? {};

    if (idAsField && (id === undefined || docData[id] === undefined)) {
      throw new TypeError(`'${id}' field does not exist in doc`);
    }

    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);

    const docRef =
      id === undefined
        ? doc(collectionRef)
        : idAsField
          ? doc(firestore, `${collectionPath}/${docData[id]}`)
          : doc(firestore, `${collectionPath}/${id}`);

    recoverTimestamp(docData);

    // metadata 추가
    if (addMetadata) {
      docData._timeUpdate = Timestamp.now();
      docData._id = docRef.id;
    }

    try {
      if (bBatch) {
        this.batch?.set(docRef, docData, { merge: bMerge });
        await this.batchAdded();
      } else {
        await FirebaseManager.runFirestoreAPI(fnName, collectionPath, () =>
          firebaseSetDoc(docRef, docData, { merge: bMerge })
        );
      }
    } catch (error: any) {
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      logger.error(error);
      throw error;
    }

    return docRef.id;
  }

  /**
   * 동일 document path에 이미 존재하는 경우에는 에러
   *
   * @params collectionPath
   * @params id
   * @params doc
   * @params options
   * - idAsField = false: false => id doc의 id로 사용, true => id는 doc의 필드를 가리킨다.
   * - addMetadata = true: _id, _timeCreate 필드를 자동으로 생성한다.
   * - bBatch = false: batch에 추가한다. batchStart(), batchEnd()와 함께 사용한다.
   */
  public async createDoc(
    collectionPath: string,
    id: string | undefined,
    docData: DocumentData,
    options?: {
      idAsField?: boolean;
      addMetadata?: boolean;
      bBatch?: boolean;
    }
  ) {
    const fnName = 'createDoc';
    const { idAsField = false, addMetadata = true, bBatch = false } = options ?? {};

    if (idAsField && (id === undefined || docData[id] === undefined)) {
      throw new TypeError(`'${id}' field does not exist in doc`);
    }

    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);

    const docRef =
      id === undefined
        ? doc(collectionRef)
        : idAsField
          ? doc(firestore, `${collectionPath}/${docData[id]}`)
          : doc(firestore, `${collectionPath}/${id}`);

    recoverTimestamp(docData);

    // metadata 추가
    if (addMetadata) {
      docData._id = docRef.id;
      docData._timeCreate = Timestamp.now();
    }

    const getDocData = await firebaseGetDoc(docRef);

    if (getDocData.exists()) {
      logger.error(`[${fnName}::${collectionPath}] ${collectionPath}:${JSON.stringify(docData)}, 이미 존재하는 Doc`);
      throw new Error('이미 존재하는 doc');
    }

    try {
      // 혹시 이미 존재하는 doc이면 여기까지 올 수 없지만 만약 통과했다면 merge 해준다.
      if (bBatch) {
        this.batch?.set(docRef, docData, { merge: true });
        await this.batchAdded();
      } else {
        await FirebaseManager.runFirestoreAPI(fnName, collectionPath, () =>
          firebaseSetDoc(docRef, docData, { merge: true })
        );
      }
    } catch (error: any) {
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      logger.error(`[${fnName}::${collectionPath}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }

    return docRef.id;
  }

  /**
   * 이미 document가 존재해야 한다.
   * document 전체를 변경하는 것이 아니라
   * 겹치지 않는 최상위 필드는 유지한다.
   *
   * - options.idAsField = false: false => id doc의 id로 사용, true => id는 doc의 필드를 가리킨다.
   * - options.addMetada = true: _id, _timeUpdate 필드를 자동으로 생성한다.
   * - options.bBatch = false: batch에 추가한다. batchStart(), batchEnd()와 함께 사용한다.
   */
  public async updateDoc(
    collectionPath: string,
    id: string,
    docData: UpdateData<any>,
    options?: {
      idAsField?: boolean;
      addMetadata?: boolean;
      bBatch?: boolean;
    }
  ) {
    const fnName = 'updateDoc';

    const { idAsField = false, addMetadata = true, bBatch = false } = options ?? {};

    if (id === undefined) {
      throw new TypeError('id must exist');
    }

    const firestore = this.firestore;
    const docRef = idAsField
      ? doc(firestore, `${collectionPath}/${docData[id]}`)
      : doc(firestore, `${collectionPath}/${id}`);

    recoverTimestamp(docData);

    // metadata 추가
    if (addMetadata) {
      docData._id = docRef.id;
      docData._timeUpdate = Timestamp.now();
    }

    try {
      if (bBatch) {
        this.batch?.update(docRef, docData);
        await this.batchAdded();
      } else {
        await FirebaseManager.runFirestoreAPI(fnName, collectionPath, () => firebaseUpdateDoc(docRef, docData));
      }
    } catch (error: any) {
      console.error(error);
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      logger.error(`[${fnName}::${collectionPath}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }

    return docRef.id;
  }

  /**
   * doc를 읽어서 응답한다.
   * 못 찾으면 Promise<undefined>를 리턴한다.
   *
   * @param docPath ex) 'unifiedOrder/1234'
   * @param fromServer true이면 서버에서만 읽어온다.
   */
  public async getDoc<T>(docPath: string, fromServer = false) {
    const fnName = 'getDoc';

    try {
      const firestore = this.firestore;
      const docRef = doc(firestore, docPath);
      const documentSnapshot = !fromServer
        ? await FirebaseManager.runFirestoreAPI(fnName, docPath, () => firebaseGetDoc(docRef))
        : await FirebaseManager.runFirestoreAPI(fnName, docPath, () => getDocFromServer(docRef));

      // exists는 document가 존재하고 내용도 있다는 뜻이다.
      if (documentSnapshot.exists()) {
        const doc = documentSnapshot.data() as T;
        // 발생할 확률이 0이지만 혹시나 해서 추가해 본다.
        if (doc === undefined) {
          throw new Error('No doc');
        }
        return doc;
      } else {
        return undefined;
      }
    } catch (error: any) {
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      logger.error(`[${fnName}::${docPath}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   * 해당 collection의 조건에 맞는 docs 배열을  리턴한다.
   *
   * @param collectionPath ex) 'unifiedOrder'
   * @param wheres 조건 배열
   * @param options
   * - sortKey: 정렬할 필드명
   * - orderBy: 정렬(오름차, 내림차)
   * - startAfter, endBefore: 조회 시작~끝 조건
   */
  public async getDocsArrayWithWhere<T>(
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      sortKey: string; // ex. '_timeCreate'
      orderBy: 'asc' | 'desc';
      startAfter?: any;
      endBefore?: any;
      limit?: number;
    }
  ) {
    const fnName = 'getDocsArrayWithWhere';

    try {
      const querySnapshot = await this.querySnapshotWithWhere<T>(fnName, collectionPath, wheres, options);

      return querySnapshot.docs.map((queryDocumentSnapshot) => queryDocumentSnapshot.data());
    } catch (error: any) {
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      console.error(error);
      logger.error(`[${fnName}::${collectionPath}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   * 해당 collection의 조건에 맞는 docs를 리턴한다.
   *
   * 응답 형태는 docId를 key로 하는 Object가 된다.
   */
  public async getDocsWithWhere<T>(
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      // selectField?: string[], // Node AdminSDK만 가능
      sortKey: string; // ex. '_timeCreate'
      orderBy: 'asc' | 'desc';
      limit?: number;
      startAfter?: any;
      endBefore?: any;
    }
  ) {
    const fnName = 'getDocsWithWhere';

    try {
      const querySnapshot = await this.querySnapshotWithWhere<T>(fnName, collectionPath, wheres, options);

      return querySnapshot.docs.reduce(
        (docs, queryDocumentSnapshot) => {
          docs[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
          return docs;
        },
        {} as { [docId: string]: T }
      );
    } catch (error: any) {
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      logger.error(`[${fnName}::${collectionPath}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   * doc를 삭제한다.
   *
   * @param docPath ex) 'unifiedOrder/1234'
   */
  public deleteDocument(docPath: string) {
    const firestore = this.firestore;
    const docRef = doc(firestore, docPath);
    return deleteDoc(docRef);
  }

  /**
   * 간단한 where 조건과 orderBy 조건으로 조회한 valueChanges Observable을 반환
   *
   * ex
   * - observeCollection('product', [['productId', '==', 'P10001']])
   * - observeCollection('product', [['productId', '==', 'P10001']], { sortKey: 'orderDate', orderBy: 'desc'})
   * - observeCollection('product', [['productId', '==', 'P10001']], { sortKey: 'orderDate', orderBy: 'desc', startAfter: '...'})
   * - observeCollection('product', [['productId', '==', 'P10001']], { sortKey: 'orderDate', orderBy: 'desc', startAfter: '...', endBefore: '...'})
   *
   * @param collectionPath ex) 'product'
   * @param wheres 조건 배열
   * @param options
   * - sortKey: 정렬할 필드명
   * - orderBy: 정렬(오름차, 내림차)
   * - startAfter, endBefore: 조회 시작~끝 조건
   */
  public observeCollection<T>(
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      sortKey: string;
      orderBy: 'asc' | 'desc';
      startAfter?: any;
      endBefore?: any;
      limit?: number;
    }
  ) {
    const fnName = 'observeCollection';

    return new Observable<T[]>((subscriber) => {
      const queryConstraints: QueryConstraint[] = [
        ...this.defaultQueryConstraints(options),
        ...wheres.map((_where: WHERE) => where(_where[0], _where[1], _where[2])),
      ];

      const firestore = this.firestore;
      const collectionRef = collection(firestore, collectionPath);
      const queryResult = query(collectionRef, ...queryConstraints) as Query<T>;

      const unsubscribeSnapshot = onSnapshot(
        queryResult,
        (querySnapshot) => {
          const docs = querySnapshot.docs.map((doc) => doc.data() as T);
          subscriber.next(docs);
        },
        (error) => {
          subscriber.error(error);
          throw error;
        },
        () => {
          subscriber.complete();
        }
      );

      const unsubscribe = () => {
        unsubscribeSnapshot();
        logger.info(`[${fnName}::${collectionPath}] unsubscribe: ${collectionPath}`);
      };

      return unsubscribe;
    });
  }

  /**
   * 하나의 도큐먼트만 관찰한다.
   *
   * @param documentPath ex) 'user/uid'
   */
  public observeDoc<T>(documentPath: string) {
    const fnName = 'observeDoc';

    return new Observable<T>((subscriber) => {
      const firestore = this.firestore;
      const docRef = doc(firestore, documentPath);
      const unsubscribeSnapshot = onSnapshot(docRef, {
        next: (querySnapshot) => {
          const doc = querySnapshot.data() as T;
          subscriber.next(doc);
        },
        error: (error) => {
          console.error(error);
          subscriber.error(error);
          // 이미 error를 하고 있으므로 throw하지 않는다.
          // 에러 발생시 알아서 unsubscribe 된다.
          // throw error;
        },
        complete: () => {
          subscriber.complete();
        },
      });

      const unsubscribe = () => {
        unsubscribeSnapshot();
        logger.info(`[${fnName}] unsubscribe: ${documentPath}`);
      };

      return unsubscribe;
    });
  }

  public createUserWithEmailAndPassword(email: string, password: string) {
    const auth = this.auth;
    return createUserWithEmailAndPassword(auth, email, password);
  }

  public signIn(email: string, password: string) {
    const auth = this.auth;
    return signInWithEmailAndPassword(auth, email, password);
  }

  public sendPasswordResetEmail(email: string) {
    const auth = this.auth;
    return sendPasswordResetEmail(auth, email);
  }

  public signOut() {
    return signOut(this.auth);
  }

  public getCurrentUser() {
    return this.auth.currentUser;
  }

  public observeAuthState() {
    const fnName = 'observeAuthState';

    return new Observable<User | null>((subscriber) => {
      const unsubscribeSnapshot = onAuthStateChanged(
        this.auth,
        (user) => {
          subscriber.next(user);
        },
        (error) => {
          subscriber.error(error);
          throw error;
        },
        () => {
          subscriber.complete();
        }
      );

      const unsubscribe = () => {
        unsubscribeSnapshot();
        logger.info(`[${fnName}] unsubscribe: AuthState`);
      };

      return unsubscribe;
    });
  }

  public getFirestoreRandomId(collectionPath: string) {
    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);
    const docRef = doc(collectionRef);
    const id = docRef.id;

    return id;
  }

  /**
   * 실제 데이터를 제외한 쿼리의 갯수만 가져온다.
   * 집계 쿼리는 쿼리에 해당하는 데이터 1,000개당 하나의 읽기 요금이 부과된다.
   * {@link https://firebase.google.com/docs/firestore/pricing?hl=ko#aggregation_queries 집계쿼리 비용}
   */
  public async getCollectionCount(collectionPath: string, wheres: WHERE[]) {
    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);
    const queryConstraints: QueryConstraint[] = [
      ...wheres.map((_where: WHERE) => where(_where[0], _where[1], _where[2])),
    ];
    const qeuryResult = query(collectionRef, ...queryConstraints);
    const snapshot = await getCountFromServer(qeuryResult);
    return snapshot.data().count;
  }

  /**
   * 좀 더 복잡한 상황(ex_ pagination)에서 사용에 적합하도록
   * 새로만들었다.
   * @param collectionPath
   * @param wheres
   * @param queries
   * @param itemsPerQuery
   * @returns
   */
  public async getDocsArrayWithQuery<T>(
    collectionPath: string,
    wheres: WHERE[],
    queries: QueryOption[],
    itemsPerQuery?: number
  ) {
    const fnName = 'getDocsArrayWithQuery';

    try {
      // 1. 검색 조건 적용
      const queryConstraints: QueryConstraint[] = [
        ...this.setQueryConstraints(queries, itemsPerQuery),
        ...wheres.map((_where: WHERE) => where(_where[0], _where[1], _where[2])),
      ];

      // 2. 조회
      const firestore = this.firestore;
      const collectionRef = collection(firestore, collectionPath);
      const qeuryResult = query(collectionRef, ...queryConstraints);
      const snapshot = await FirebaseManager.runFirestoreAPI(
        fnName,
        collectionPath,
        () => getDocs(qeuryResult) as Promise<QuerySnapshot<T>>
      );

      return snapshot.docs.map((doc) => doc.data() as T);
    } catch (error: any) {
      if (error.code === 'unavailable') {
        throw new Error('인터넷 연결이 불안정합니다. 연결 상태를 확인해 주세요.');
      }
      console.error(error);
      logger.error(`[${fnName}::${collectionPath}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   *  firestore api 호출이 실패하는 경우 최소 n(maxTry)번 재시도를 한다.
   */
  private static async runFirestoreAPI<T>(caller: string, collectionOrDocPath: string, api: () => Promise<T>) {
    const maxTry = 3;
    let countTry = 0;

    while (countTry < maxTry) {
      try {
        // 없으면 만들고 있으면 덮어쓴다.
        countTry++;
        if (countTry > 1) {
          // logger.error(`[${caller}:${collectionOrDocPath}] countTry = ${countTry}, diffTime = ${diffTimestamp(caller)}`);
          logger.error(`[${caller}:${collectionOrDocPath}] countTry = ${countTry}`);
        }

        // DEADLINE_EXCEEDED 방지를 위해
        // await timeMargin(caller, 500);
        return api();
        // await docRef.set(doc, { merge: bMerge });
        // 성공한 경우에는 루프를 빠져나간다.
      } catch (error: any) {
        // 마지막 try에서의 throw 처리는 아래에서 수행한다.
        // 2: "details": "Stream removed"
        // 4: DEADLINE_EXCCEDED
        // 10: "details": "Too much contention on these documents. Please try again."
        // 13: "details": ""
        // 13: "details": "An internal error occurred."
        // 14: "details": "The service is temporarily unavailable. Please retry with exponential backoff."
        // 14: "details": "Transport closed"
        // 14: "details": "GOAWAY received"
        if (countTry < maxTry && [2, 4, 10, 13, 14].includes(error.code)) {
          // logger.error(`[${caller}] error at countTry = ${countTry}, error = ${JSON.stringify(error, undefined, 2)}`);
          await sleep(2000);
          continue;
        }

        // logger.error(`[${caller}] Give Up. Should the page be reloaded???. countTry = ${countTry}, error = ${JSON.stringify(error, undefined, 2)}`);
        throw error;
      }
    }

    // typescript에서 return undefined로 인지하지 못 하도록
    throw new Error('Unexpected reach');
  }

  /**
   * this.batch에 추가한 후에 반드시 실행한다.
   */
  private async batchAdded() {
    const fnName = 'batchAdded';

    if (this.batch === undefined) {
      logger.error(`[${fnName}] No this.batch. Run batchStart() first.`);
      return false;
    }

    this.batchCount++;

    if (this.batchCount >= this.MaxBatchNum) {
      logger.info(`[${fnName}] batchCount == ${this.batchCount} => Run batch.commit()`);
      await this.batch.commit();

      // 비웠으니 다시 시작한다.
      this.batchStart();
    }

    return undefined;
  }

  /**
   * getDocsWithWheres와 getDocsArrayWithWheres의 공통 부분
   */
  private querySnapshotWithWhere<T>(
    fnName: string,
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      // selectField?: string[], // Node AdminSDK만 가능
      sortKey: string; // ex. '_timeCreate'
      orderBy: 'asc' | 'desc';
      startAfter?: any;
      endBefore?: any;
      limit?: number;
    }
  ) {
    // 1. 검색 조건 적용
    const queryConstraints: QueryConstraint[] = [
      ...this.defaultQueryConstraints(options),
      ...wheres.map((_where: WHERE) => where(_where[0], _where[1], _where[2])),
    ];

    // 2. 조회
    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);
    const qeuryResult = query(collectionRef, ...queryConstraints);

    return FirebaseManager.runFirestoreAPI(
      fnName,
      collectionPath,
      () => getDocs(qeuryResult) as Promise<QuerySnapshot<T>>
    );
  }

  private defaultQueryConstraints(options?: {
    sortKey: string;
    orderBy: 'asc' | 'desc';
    startAfter?: any;
    endBefore?: any;
    limit?: number;
  }): QueryConstraint[] {
    const limitOption = options?.limit ? [limit(options.limit)] : [];
    if (options?.startAfter && options?.endBefore) {
      // 조회 start~end 조건이 모두 있는 경우
      return [
        orderBy(options.sortKey, options.orderBy),
        options.orderBy === 'asc' ? startAfter(options.startAfter) : endBefore(options.startAfter),
        options.orderBy === 'asc' ? endBefore(options.endBefore) : startAfter(options.endBefore),
        ...limitOption,
      ];
    } else if (options?.startAfter) {
      // 조회 시작 조건만 있는 경우
      return [
        orderBy(options.sortKey, options.orderBy),
        options.orderBy === 'asc' ? startAfter(options.startAfter) : endBefore(options.startAfter),
        ...limitOption,
      ];
    } else if (options?.endBefore) {
      // 끝 조건만 있는 경우
      return [
        orderBy(options.sortKey, options.orderBy),
        options.orderBy === 'asc' ? endBefore(options.endBefore) : startAfter(options.endBefore),
        ...limitOption,
      ];
    } else if (options?.sortKey) {
      // orderBy 조건만 있는 경우
      return [orderBy(options.sortKey, options.orderBy), ...limitOption];
    }
    // 정렬 없는 경우
    return [...limitOption];
  }

  /**
   * {@link https://firebase.google.com/docs/firestore/query-data/query-cursors Query cursors}
   *
   * @param sort default: 'asc'
   * @returns
   */
  private setQueryConstraints(queries: QueryOption[], itemsPerQuery?: number): QueryConstraint[] {
    const limitOption = itemsPerQuery ? [limit(itemsPerQuery)] : [];
    if (queries.length > 0) {
      const mainQuery = queries[0];
      const orderByOptions = queries
        // 암묵적으로 적용되는 필드는 제외한다.
        // {@link https://github.com/invertase/react-native-firebase/issues/2854#issuecomment-552986650}
        .filter((query) => query.orderBy !== '__name__')
        .map((query) => orderBy(query.orderBy, query.sort));
      const startAfterValues = queries.filter((query) => query.startAfter).map((query) => query.startAfter);
      const endBeforeValues = queries.filter((query) => query.endBefore).map((query) => query.endBefore);
      const startAfterOption = mainQuery.sort === 'asc' ? startAfter(startAfterValues) : endBefore(endBeforeValues);
      const endBeforeOption = mainQuery.sort === 'asc' ? endBefore(endBeforeValues) : startAfter(startAfterValues);

      // 조회 start~end 조건이 모두 있는 경우
      if (mainQuery.startAfter && mainQuery.endBefore) {
        return [...orderByOptions, startAfterOption, endBeforeOption, ...limitOption];
      }
      // 조회 시작 조건만 있는 경우
      else if (mainQuery.startAfter) {
        return [...orderByOptions, startAfterOption, ...limitOption];
      }
      // 끝 조건만 있는 경우
      else if (mainQuery.endBefore) {
        return [...orderByOptions, endBeforeOption, ...limitOption];
      }

      // 정렬만 있는 경우
      return [...orderByOptions, ...limitOption];
    }
    return [...limitOption];
  }
}
