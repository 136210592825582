import { storeStateList } from '@gooduncles/gu-app-schema/constants';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Card, Chip, Stack, Typography } from '@mui/joy';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import useStoresCount from 'src/hooks/useStoresCount';
import { useTitle } from 'src/hooks/useTitle';

import PageContainer from 'src/components/atoms/PageContainer/PageContainer';

const DashboardPage: FC = () => {
  useTitle('Dashboard');
  const { count: open } = useStoresCount('open');
  const { count: guest } = useStoresCount('guest');

  return (
    <PageContainer>
      <Typography level='h2' sx={{ marginBottom: 1 }}>
        매장 현황
      </Typography>
      <Stack direction='row' spacing={1}>
        <Link to='/store/list?state=guest' style={{ flex: 1 }}>
          <Card variant='outlined'>
            <Chip size='sm' variant='solid' color='primary'>
              {storeStateList['guest']}
            </Chip>
            <Stack direction='row'>
              <Typography level='h3' sx={{ fontWeight: 700, mr: 'auto' }}>
                {guest}
              </Typography>
              <KeyboardArrowRight />
            </Stack>
          </Card>
        </Link>

        <Link to='/store/list?state=open' style={{ flex: 1 }}>
          <Card variant='outlined'>
            <Chip size='sm' variant='solid' color='success'>
              {storeStateList['open']}
            </Chip>
            <Stack direction='row'>
              <Typography level='h3' sx={{ fontWeight: 700, mr: 'auto' }}>
                {open}
              </Typography>
              <KeyboardArrowRight />
            </Stack>
          </Card>
        </Link>
      </Stack>
    </PageContainer>
  );
};

export default DashboardPage;
