import { Button, FormLabel, Select, Stack, Typography } from '@mui/joy';
import { FC, useCallback, useState } from 'react';
import { updateDoc } from 'src/services/commonService';
import { errorObjectToString } from 'src/utils/common';

import useModal from 'src/redux/hooks/useModal';

interface EditableSelectFieldProps {
  label: string;
  field: string;
  value: string;
  collectionPath: string;
  docId: string;
  options: JSX.Element[];
  disabled?: string;
}

const EditableSelectField: FC<EditableSelectFieldProps> = ({
  label,
  field,
  value,
  collectionPath,
  docId,
  options,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();

  const handleOnEdit = useCallback(
    async (_: any, newValue: string | null) => {
      if (newValue === value || !newValue) {
        return;
      }
      setLoading(true);
      try {
        await updateDoc(collectionPath, docId, { [field]: newValue });
      } catch (error) {
        console.error(error);
        const errorMsg = errorObjectToString(error);
        openModal('danger', '수정 실패', errorMsg);
      }
      setLoading(false);
    },
    [collectionPath, docId, field, openModal, value]
  );

  return (
    <Stack>
      {label && <FormLabel>{label}</FormLabel>}
      {loading ? (
        <Button loading disabled />
      ) : (
        <Select value={value} onChange={handleOnEdit} disabled={!!disabled}>
          {options}
        </Select>
      )}
      {disabled && (
        <Typography level='body-xs' color='warning' sx={{ mt: 1 }}>
          {disabled}
        </Typography>
      )}
    </Stack>
  );
};

export default EditableSelectField;
