import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary } from '@mui/joy';
import { FC, PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface NavigationGroupProps {
  title: string;
  groupPath: string;
}

const NavigationGroup: FC<PropsWithChildren<NavigationGroupProps>> = ({ children, title, groupPath }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isActive = currentPath.includes(groupPath);

  // Controlled component를 위한 상태
  const [expanded, setExpanded] = useState(isActive);

  // Accordion의 변경을 처리하는 함수
  const handleAccordionChange = (_: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <>
      <AccordionGroup>
        <Accordion expanded={expanded} onChange={handleAccordionChange}>
          <AccordionSummary color={isActive ? 'primary' : undefined}>{title}</AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </>
  );
};

export default NavigationGroup;
