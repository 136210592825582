import { CircularProgress, DialogContent, DialogTitle, Modal, ModalDialog } from '@mui/joy';
import { FC } from 'react';

import { selectIsLoading } from 'src/redux/slices/loading';
import { useAppSelector } from 'src/redux/store';

const LoadingModal: FC = () => {
  const isLoading = useAppSelector(selectIsLoading);
  return (
    <Modal open={isLoading}>
      <ModalDialog variant='soft' sx={{ py: 5 }}>
        <DialogTitle sx={{ justifyContent: 'center', marginBottom: 2 }}>잠시만 기다려주세요.</DialogTitle>
        <DialogContent>
          <CircularProgress sx={{ mx: 'auto' }} size='md' variant='soft' />
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default LoadingModal;
