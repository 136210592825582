import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

const UserPage: FC = () => {
  useTitle('User');
  return <div>UserPage</div>;
};

export default UserPage;
