import { List, ListItem } from '@mui/joy';
import { FC } from 'react';

import NavigationGroup from 'src/components/atoms/NavigationGroup';
import NavigationItem from 'src/components/atoms/NavigationItem';

const NaviList: FC = () => {
  return (
    <List>
      <NavigationItem title='대시보드' path='/dashboard' />
      {/* <NavigationItem title='User' path='/user' /> */}
      <NavigationGroup title='매장 관리' groupPath='/store'>
        <ListItem nested>
          <List>
            <NavigationItem title='매장 목록' path='/store/list' />
          </List>
        </ListItem>
      </NavigationGroup>
      {/* <ListItem>Order</ListItem>
      <ListItem>Product</ListItem>
      <ListItem>Delivery</ListItem>
      <ListItem>Issue</ListItem>
      <ListItem>Settlement</ListItem> */}
    </List>
  );
};

export default NaviList;
