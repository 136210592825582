import { useEffect, useState } from 'react';
import { EMPTY, Subscription, catchError } from 'rxjs';
import { isProduction, logArt } from 'src/constants';
import { observeFirebaseAuth } from 'src/services/authService';
import { observeUserDoc, updateUser } from 'src/services/userService';
import { getPublicIp, sessionId } from 'src/utils/common';
import { validateUser } from 'src/utils/validateAuth';

import useAuth from 'src/redux/hooks/useAuth';
import useLoading from 'src/redux/hooks/useLoading';
import { UserExceptFavoriteProducts } from 'src/redux/models/authModel';
import { selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

let userSubscription: Subscription;

/**
 * 앱의 전체 로그인 상태를 관리하는 커스텀 훅.
 * firebase auth의 상태관리를 observe(관찰)하여 해당 상태에 따라 로그인/로그아웃을 처리한다.
 */
const useAuthentication = () => {
  const [userForInit, setUserForInit] = useState<UserExceptFavoriteProducts | null>();
  const user = useAppSelector(selectUser);
  const { handleSetLoading } = useLoading();

  const { handleSignOut, handleSetUser } = useAuth();

  useEffect(() => {
    const authSubscription = observeFirebaseAuth().subscribe(async (firebaseAuth) => {
      const { uid, email } = firebaseAuth ?? {};

      if (uid && email) {
        const ip = await getPublicIp();
        await updateUser(uid, {
          email,
          ip,
          sessionId: sessionId() ?? undefined,
        });
        userSubscription = observeUserDoc(uid)
          .pipe(
            catchError((error) => {
              console.error(error);
              handleSignOut();
              return EMPTY;
            })
          )
          .subscribe((user0) => {
            validateUser(user0);
            const { favoriteProducts, ...user1 } = user0;
            handleSetUser(user1);
          });
      } else {
        userSubscription?.unsubscribe();
        // 비회원의 최초 로딩이 해제되는 시점.
        // handleAuthLoading(false);
        handleSignOut();
      }
    });

    return () => {
      authSubscription.unsubscribe();
      userSubscription?.unsubscribe();
    };
  }, [handleSignOut, handleSetUser]);

  // 최초 로그인 여부 판단
  useEffect(() => {
    if (user?._id !== userForInit?._id) {
      setUserForInit(user);
    } else if (!user) {
      setUserForInit(null);
    }
  }, [user, userForInit?._id]);

  // 최초 로그인에 따른 로그
  useEffect(() => {
    if (userForInit === undefined) {
      handleSetLoading(true);
      console.log(`%c\n${logArt}`, isProduction ? 'color:orange' : 'color:black');
    }
  }, [handleSetLoading, userForInit]);
};

export default useAuthentication;
