import { StoreDoc, StoreState } from '@gooduncles/gu-app-schema';
import { storeStateList } from '@gooduncles/gu-app-schema/constants';
import { Chip, ColorPaletteProp, Typography } from '@mui/joy';
import { FC } from 'react';

import classes from './StoreItem.module.scss';

const chipColors: Record<StoreState, ColorPaletteProp> = {
  open: 'success',
  guest: 'neutral',
  break: 'warning',
  closed: 'danger',
};

interface StoreItemProps extends React.HTMLAttributes<HTMLDivElement> {
  store: StoreDoc;
}

const StoreItem: FC<StoreItemProps> = ({ store, ...props }) => {
  return (
    <div className={classes.storeItemBox} {...props}>
      <div className={classes.storeHeader}>
        <Typography level='title-lg'>{store.storeNickname}</Typography>
        <Chip color={chipColors[store.state]} variant='soft'>
          {storeStateList[store.state]}
        </Chip>
      </div>
      <div className={classes.storeBody}>
        {store.storeCode ? (
          <Typography level='body-sm'>{store.storeCode}</Typography>
        ) : (
          <Typography level='body-sm' sx={{ opacity: 0.5 }}>
            미등록
          </Typography>
        )}
        <Typography level='body-xs'>{store.storeEmail}</Typography>
      </div>
    </div>
  );
};

export default StoreItem;
